import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'grommet';

const DynamicGalleryFilterToggleButton = ({
  blok,
  onToggleFilterPanel,
  activeFilters,
}) => {
  return (
    <Button
      label={`${blok.label}${
        activeFilters.length ? ` (${activeFilters.length})` : ''
      }`}
      primary
      onClick={() => onToggleFilterPanel()}
    />
  );
};

DynamicGalleryFilterToggleButton.propTypes = {
  blok: PropTypes.object,
  onToggleFilterPanel: PropTypes.func.isRequired,
  activeFilters: PropTypes.array,
};

export default DynamicGalleryFilterToggleButton;
